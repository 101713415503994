import { Col, Row, Card, CardBody, Form, FormGroup, Label, Input, Button, Spinner } from 'reactstrap';
import { createTagById, updateTagById } from '../../../../services/tags';
import { ToastContainer, toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { HeadFC, Link, navigate } from 'gatsby';
import SideNav from '../../components/sidenav';
import AuthContext from '../../contexts/auth';

const AdminNewTag = ({ id, name, loading }: { id: string, name: string, loading: boolean }) => {
  const [newsCategory, setNewsCategory] = useState({
    id: id,
    name: name,
  });
  const [submitEnabled, setSubmitEnabled] = useState(false);

  useEffect(() => {
    setNewsCategory((prevState) => ({ ...prevState, name }));
  }, [name]);

  useEffect(() => {
    const userIsDefined = newsCategory.name !== '';
    setSubmitEnabled(userIsDefined);
  }, [newsCategory]);

  const onSubmitCreateOrUpdateUserForm = (e: any) => {
    e.preventDefault();

    toast.promise(id ? sendUpdateUser : sendCreateUser,
      {
        pending: `${id ? 'Actualizando': 'Creando'} tag`,
        success: `tag ${id ? 'Actualizado': 'Creado'} 👌`,
        error: `Error al ${id ? 'Actualizar': 'Crear'} tag 🤯`
      });
  }

  const sendUpdateUser = async () => {
    await updateTagById(newsCategory.id, newsCategory.name);
    navigate('/admin/tags-de-noticias');
  }

  const sendCreateUser = async () => {
    await createTagById(newsCategory.id, newsCategory.name);
    navigate('/admin/tags-de-noticias');
  }

  return (
    <AuthContext>
      <ToastContainer />
      <div className="admin-container d-flex flex-row w-100">
        <SideNav
          activeMenu='categorias-de-noticias'
        />
        <Row className="admin-page-content d-flex flex-column align-items-center">
          <Col md={11}>
            <Card className="w-100 p-4 mb-5">
              <CardBody>
                <h4>
                  <Link
                    to="/admin/categorias-de-noticias"
                  >
                    <i className="bi bi-arrow-left-square-fill"></i>
                  </Link>{" "}
                  {id ? 'Actualizar' : 'Nueva'} tag de noticia

                  {loading &&
                  <Spinner
                    color="primary"
                    style={{
                      height: '3rem',
                      width: '3rem'
                    }}
                  >
                    Loading...
                  </Spinner>}
                </h4>
                {((id && newsCategory.name !== '') || !id)
                && <Form className="mt-4" onSubmit={(e) => onSubmitCreateOrUpdateUserForm(e)}>
                  <Row>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="news_category_name" className="mb-2">
                          Nombre
                        </Label>
                        <Input
                          type="text"
                          id="news_category_name"
                          value={newsCategory.name}
                          onChange={(e) => setNewsCategory((prevState) => ({ ...prevState, name: e.target.value }))}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <FormGroup>
                        <Button
                          type="submit"
                          color="success"
                          className="w-100"
                          disabled={!submitEnabled}
                        >
                          {id ? 'Actualizar' : 'Crear'} tag de noticia
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </AuthContext>
  );
}

export default AdminNewTag;

export const Head: HeadFC = () => <title>LaBotana : Admin - Nueva tag de noticia</title>
